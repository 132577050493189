.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Green Button */

.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  font-size: 17px;
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #1dab4b !important;
  border-color: #1dab4b !important;
  border-radius: 8px !important;
}
.common_green_Btn {
  background-color: var(--green) !important;
  color: var(--white) !important;
  outline: none;
  border: none;
  border-radius: 10px;
  box-shadow: none !important;
}

.common_green_Btn:focus,
.common_green_Btn:active {
  background: var(--yellow) !important;
  color: var(--lite-black) !important;
  box-shadow: none !important;
}

.common_green_Btn:hover {
  background: var(--yellow) !important;
  color: var(--lite-black) !important;
}

/* Yellow Button */

.common_yellow_Btn {
  background-color: var(--yellow);
  color: var(--lite-black);
  outline: none;
  border: none;
  box-shadow: none !important;
  border-radius: 10px !important;
}

.common_yellow_Btn:focus,
.common_yellow_Btn:active {
  background: var(--green);
  color: var(--lite-black);
  box-shadow: none !important;
}

.common_yellow_Btn:hover {
  background: var(--green);
  color: var(--white);
}

/* Green Border Button */

.common_green_br_Btn {
  background-color: transparent;
  color: var(--green);
  outline: none;
  border-radius: 10px;
  border: 1px solid var(--green);
  box-shadow: none !important;
}

.common_green_br_Btn:focus,
.common_green_br_Btn:active {
  background-color: transparent;
  border: 1px solid var(--yellow);
  box-shadow: none !important;
  color: var(--yellow);
}

.common_green_br_Btn:hover {
  color: var(--yellow);
  background-color: transparent;
  border: 1px solid var(--yellow);
}

/*Create Acc*/

.create-acc {
  padding-top: 90px;
}

.create-acc .register-btn {
  font-weight: 600;
  height: 60px;
  font-size: 21px;
}

.create-acc .login-btn {
  height: 60px;
  font-weight: 600;
  font-size: 21px;
}

.create-acc .logo-img {
  width: 350px;
  height: 350px;
  object-fit: contain;
  margin: 0 auto 25px auto;
}

.create-acc .logo-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 380px) {
  .create-acc .logo-img {
    width: 225px;
    height: 217px;
  }

  .create-acc .register-btn {
    height: 50px;
    font-size: 18px;
  }

  .create-acc .login-btn {
    height: 50px;
    font-size: 18px;
  }
}

/*Register*/
.register-page {
  padding-top: 90px;
}

.register-page .header {
  color: var(--black);
  font-size: 32px;
  font-weight: 600;
}

.register-page label {
  color: var(--dark-grey);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 1rem;
}

.register-page input {
  border-radius: 3px;
  background-color: var(--lite-grey);
  border: none;
  outline: none;
  box-shadow: 0px;
  height: 55px;
  color: var(--dark-grey) !important;
}

.register-page input:focus {
  border-radius: 3px;
  background-color: var(--lite-grey);
  box-shadow: none !important;
}

.register-page .sign-up-btn {
  font-weight: 600;
  height: 60px;
}

.register-page .account-verify {
  color: var(--dark-grey);
  font-weight: 500;
  font-size: 18px;
}

.register-page .account-verify a {
  color: var(--green);
  font-size: 20px;
  cursor: pointer;
}

.register-page .account-verify a:hover {
  color: var(--yellow);
}

/* Connect Wallet */
.connect_wallet {
  padding-top: 90px;
}

.connect_wallet .header {
  color: var(--dark-grey);
  font-weight: 700;
}

.connect_wallet .cmn-btn {
  height: 60px;
  background-color: var(--lite-grey);
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: 500;
  margin: 20px 0px;
  color: var(--dark-grey);
}

.connect_wallet .cmn-btn:hover,
.connect_wallet .cmn-btn:active,
.connect_wallet .cmn-btn:focus {
  background-color: var(--lite-grey);
  box-shadow: none !important;
  color: var(--dark-yellow);
}

.connect_wallet .show-more {
  /* background-color: var(--grey); */
  height: 60px;
  font-weight: 600;
}

.connect_wallet .img_sec img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

/* Purchase Bot */
.purchase-bot {
  padding-top: 90px;
}

.purchase-bot .card {
  background-color: var(--blue-grey);
  border-radius: 24px;
}

.purchase-bot .small-bot-sec {
  background: var(--white);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.purchase-bot .bot-check input {
  width: 30px;
  height: 30px;
  border-radius: 8px !important;
  border: 0px !important;
  box-shadow: none !important;
  background-color: var(--green) !important;
}

.purchase-bot .amount {
  color: var(--green);
  font-size: 29px;
  font-weight: 700;
}

.purchase-bot .trade {
  color: var(--dark-grey);
  font-size: 24px;
  font-weight: normal;
}

.purchase-bot .activate-bot {
  height: 65px;
  border-radius: 0px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  /* margin-bottom: -1px; */
  font-weight: 600;
  font-size: 20px;
}

.purchase-bot .big-bot {
  width: 200px;
  height: 150px;
  object-fit: contain;
}

.register_custom_btn {
  padding: 6px 35px;
}

.purchase-bot .period-check input {
  width: 30px;
  height: 30px;
  border-radius: 8px !important;
  border: 1px solid var(--green) !important;
  box-shadow: none !important;
  background-color: transparent !important;
  margin-top: 0px !important;
}

.computing_period h6 {
  color: var(--dark-grey);
  font-weight: 600;
  font-size: 23px;
}

.purchase-bot .proceed_btn {
  height: 60px;
  font-size: 20px;
  font-weight: 600;
}

.purchase-bot .period-check input:checked[type="checkbox"] {
  background-image: url(./Assets/Img/check_img.png) !important;
  background-size: 61% 50%;
}

.purchase_bot_offcanvas {
  height: 60vh !important;
  padding: 10px 50px 50px;
}

.purchase_bot_offcanvas .purchase_btn {
  height: 60px;
}

.purchase_bot_offcanvas h4 {
  color: var(--dark-grey);
  font-size: 30px;
  font-weight: 700;
}

.purchase_bot_offcanvas h6 {
  color: var(--dark-grey);
  font-size: 25px;
  font-weight: 600;
}

.order_summary_bot_img img {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.order_summary_bot_img {
  background-color: var(--blue-grey);
  border-radius: 10px;
}

.purchase_bot_offcanvas .duration {
  color: var(--dark-grey);
  font-size: 24px;
  font-weight: 500;
}

.purchase_bot_offcanvas .trade {
  color: var(--green);
  font-size: 17px;
  font-weight: 500;
}

.purchase_bot_offcanvas .amount {
  color: var(--green);
  font-weight: 600;
  font-size: 24px;
  text-align: end;
}

.purchase_bot_offcanvas .total {
  color: var(--dark-grey);
  font-size: 20px;
  font-weight: 600;
}

.purchase_bot_modal .modal-content {
  border-radius: 20px;
}

.purchase_bot_modal .modal-title {
  font-size: 20px;
  color: var(--dark-grey);
  font-weight: 500;
}

.purchase_bot_modal .modal-body {
  font-size: 16px;
  color: var(--dark-grey);
  font-weight: 500;
}

.purchase_bot_modal button {
  font-size: 15px;
  padding: 7px 20px;
}

.purchase_bot_modal .modal-footer {
  border-top: 0px !important;
}

.order_summary_popup {
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 576px) {
  .purchase_bot_offcanvas h4 {
    font-size: 17px;
  }

  .purchase_bot_offcanvas h6 {
    font-size: 15px;
    text-align: center;
  }

  .order_summary_bot_img img {
    width: 50px;
    height: 50px;
  }

  .purchase_bot_offcanvas .duration {
    font-size: 12px;
  }

  .purchase_bot_offcanvas .trade {
    font-size: 10px;
  }

  .purchase_bot_offcanvas .amount {
    font-size: 13px;
    text-align: center;
    margin-top: 10px;
  }

  .order_summary_popup {
    flex-direction: column;
    align-items: unset !important;
  }

  .purchase_bot_offcanvas .purchase_btn {
    height: 40px;
  }

  .purchase_bot_offcanvas {
    height: 45vh !important;
  }

  .purchase_bot_offcanvas .total {
    font-size: 15px;
  }

  .bot_subscription_txt {
    font-size: 11px !important;
    text-align: center;
  }
}

@media (min-width: 577px) and (max-width: 991px) {
  .purchase_bot_offcanvas h4 {
    font-size: 20px;
  }

  .purchase_bot_offcanvas h6 {
    font-size: 18px;
  }

  .order_summary_bot_img img {
    width: 100px;
    height: 100px;
  }

  .purchase_bot_offcanvas .duration {
    font-size: 19px;
  }

  .purchase_bot_offcanvas .trade {
    font-size: 17px;
  }

  .purchase_bot_offcanvas .amount {
    font-size: 16px;
  }

  .purchase_bot_offcanvas .purchase_btn {
    height: 50px;
  }

  .purchase_bot_offcanvas {
    height: 53vh !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .purchase_bot_offcanvas h4 {
    font-size: 22px;
  }

  .purchase_bot_offcanvas h6 {
    font-size: 19px;
  }

  .order_summary_bot_img img {
    width: 120px;
    height: 120px;
  }

  .purchase_bot_offcanvas .duration {
    font-size: 20px;
  }

  .purchase_bot_offcanvas .trade {
    font-size: 18px;
  }

  .purchase_bot_offcanvas .amount {
    font-size: 18px;
  }

  .purchase_bot_offcanvas .purchase_btn {
    height: 53px;
  }

  .purchase_bot_offcanvas {
    height: 56vh !important;
  }

  .meet_bot_bg {
    left: 34% !important;
    top: -4%;
    width: 350px !important;
    height: 350px !important;
    position: absolute !important;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .purchase_bot_offcanvas h4 {
    font-size: 22px;
  }

  .purchase_bot_offcanvas h6 {
    font-size: 19px;
  }

  .order_summary_bot_img img {
    width: 120px;
    height: 120px;
  }

  .purchase_bot_offcanvas .duration {
    font-size: 20px;
  }

  .purchase_bot_offcanvas .trade {
    font-size: 18px;
  }

  .purchase_bot_offcanvas .amount {
    font-size: 18px;
  }

  .purchase_bot_offcanvas .purchase_btn {
    height: 53px;
  }

  .purchase_bot_offcanvas {
    height: 57vh !important;
  }
}

@media (max-width: 767px) {
  .purchase-bot .trade {
    font-size: 16px !important;
  }
}

@media (min-width: 768px) {
  .purchase-bot .trade {
    font-size: 18px !important;
  }
}

@media (max-width: 1200px) {
  .purchase-bot .amount {
    font-size: 25px !important;
  }

  .purchase-bot .activate-bot {
    font-size: 18px !important;
  }

  .purchase-bot .proceed_btn {
    font-size: 18px !important;
  }
  .navbar {
    line-height: unset !important;
  }
}

/* Dashboard */

.dashboard .left_side_sec {
  width: 250px;
}

.dashboard .right_side_sec {
  width: calc(100% - 250px);
}

/* Dashboard Header */

.right_side_sec .dashboard_header .notification {
  border-radius: 50%;
  padding: 11px;
  width: 40px;
  height: 40px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.right_side_sec .dashboard_header .notification::after {
  display: none;
}

.right_side_sec .dashboard_header .user_Logo {
  border-radius: 50%;
  padding: 11px;
  width: 40px;
  height: 40px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.right_side_sec .dashboard_header .user_Logo::after {
  display: none;
}

.dashboard .profile {
  color: var(--dark-grey);
  font-size: 20px;
}

/* Dashboard Sidebar */
.left_side_sec .sidebar_sec {
  background-color: var(--lite-grey);
  border-radius: 0px 24px 24px 0px;
  height: 100%;
  min-height: 100vh;
  transition-duration: 2s;
  transition-timing-function: linear;
  transition-delay: 1s;
  box-shadow: 0px 3px 6px #00000029;
}

.sidebar_sec .bot_logo {
  width: 130px;
  height: 130px;
  object-fit: contain;
  cursor: pointer;
}

/* dashboard sidebar hover */

/* .dashboard .left_side_sec .sidebar_sec p {
  height: 40px;
  color: var(--white);
  background-color: var(--green);
  width: 220px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
} */

/* .dashboard .left_side_sec .sidebar_sec p::after {
  position: absolute !important;
  content: "";
  height: 27px !important;
  width: 24px !important;
  background: transparent !important;
  left: -2px !important;
  bottom: -65% !important;
  border-bottom-right-radius: 20px !important;
  box-shadow: 0 17px 0 var(--green) !important;
  transform: rotate(180deg) !important;
} */

/* .dashboard .left_side_sec .sidebar_sec p::before {
  position: absolute !important;
  content: "";
  height: 27px !important;
  width: 18px !important;
  background: transparent !important;
  left: 0 !important;
  bottom: 100% !important;
  border-bottom-left-radius: 20px;
  box-shadow: 0 17px 0 var(--green) !important;
} */

/* .dashboard .left_side_sec .sidebar_sec p:hover {
  background-color: var(--green);
  color: var(--white);
} */
/* 
.dashboard .left_side_sec .sidebar_sec p:hover::after {
  position: absolute !important;
  content: "";
  height: 27px !important;
  width: 24px !important;
  background: transparent !important;
  left: -2px !important;
  bottom: -65% !important;
  border-bottom-right-radius: 20px !important;
  box-shadow: 0 17px 0 var(--green) !important;
  transform: rotate(180deg) !important;
} */
/* 
.dashboard .left_side_sec .sidebar_sec p:hover::before {
  position: absolute !important;
  content: "";
  height: 27px !important;
  width: 18px !important;
  background: transparent !important;
  left: 0 !important;
  bottom: 100% !important;
  border-bottom-left-radius: 20px !important;
  box-shadow: 0 17px 0 var(--green) !important;
} */

.trade-history .left_side_sec .sidebar_sec .dashboard_text {
  color: var(--black);
  background-color: transparent;
}

.trade-history .left_side_sec .sidebar_sec .dashboard_text::after {
  box-shadow: none !important;
}

.trade-history .left_side_sec .sidebar_sec .dashboard_text::before {
  box-shadow: none !important;
}
/* 
.dash_board .left_side_sec .sidebar_sec .trade_history_text {
  color: var(--black);
  background-color: transparent;
}

.dash_board .left_side_sec .sidebar_sec .trade_history_text::after {
  box-shadow: none !important;
}

.dash_board .left_side_sec .sidebar_sec .trade_history_text::before {
  box-shadow: none !important;
} */

/* .dash_board .left_side_sec .sidebar_sec .wallet_text {
  color: var(--black);
  background-color: transparent;
}
.dash_board .left_side_sec .sidebar_sec .wallet_text::after {
  box-shadow: none !important;
}
.dash_board .left_side_sec .sidebar_sec .wallet_text::before {
  box-shadow: none !important;
} */

/* .trade-history .left_side_sec .sidebar_sec .wallet_text{
  color: var(--black);
  background-color: transparent;
} */
.trade-history .left_side_sec .sidebar_sec .wallet_text {
  color: var(--black);
  background-color: transparent;
}

.trade-history .left_side_sec .sidebar_sec .wallet_text::before {
  box-shadow: none !important;
}
.trade-history .left_side_sec .sidebar_sec .wallet_text::after {
  box-shadow: none !important;
}

.wallet .left_side_sec .sidebar_sec .dashboard_text::after {
  box-shadow: none !important;
}
.wallet .left_side_sec .sidebar_sec .dashboard_text::before {
  box-shadow: none !important;
}
.wallet .left_side_sec .sidebar_sec .home_text {
  color: var(--black);
  background-color: transparent;
}
.wallet .left_side_sec .sidebar_sec .home_text::after {
  box-shadow: none !important;
}
.wallet .left_side_sec .sidebar_sec .home_text::before {
  box-shadow: none !important;
}

.wallet .left_side_sec .sidebar_sec .dashboard_text {
  color: var(--black);
  background-color: transparent;
}
.wallet .left_side_sec .sidebar_sec .trade_history_text {
  color: var(--black);
  background-color: transparent;
}

.wallet .left_side_sec .sidebar_sec .trade_history_text::after {
  box-shadow: none !important;
}
.wallet .left_side_sec .sidebar_sec .trade_history_text::before {
  box-shadow: none !important;
}

/* .dash_board .left_side_sec .sidebar_sec .wallet {
  color: var(--black);
  background-color: transparent;
} */

/* .wallet .left_side_sec .sidebar_sec p {
  height: 40px;
  color: var(--white);
  background-color: var(--green);
  width: 220px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
} */

/* Content section */

.content_section .profile {
  color: var(--dark-grey-1);
  font-weight: normal;
}

.content_section .user_profile .delete_btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -33px;
  right: 0px;
  cursor: pointer;
}

.user_profile .delete_btn svg {
  color: white;
}

.content_section .user_profile img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.content_section .user_details .user_detail_name {
  color: var(--dark-grey-1);
  font-size: 16px;
  font-weight: 400;
}

.content_section .user_details .user_detail_op {
  color: var(--dark-grey-1);
  font-size: 16px;
  font-weight: 500;
}

.content_section .user_profile_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
}

.content_section .card-body {
  padding: 1.5rem;
}

.content_section .upload_profile {
  background: #1dab4b 0% 0% no-repeat padding-box;
}
/* .active_text{
  background: green;
  color: #fff;
  width: 30%;
  max-width: 30%;
  min-width: 30%;
  text-align: center;
  border-radius: 26px 10px 55px 52px;
  transform: rotate(333deg);
  position: absolute;
  top: -28px;
  left: -22px;
  padding: 50px 10px 20px 10px;
  font-weight: 500;
}
.active_text.inactive{
  background: gray !important;
}
.active_text.expiry{
  background: red !important;
} */

.ribbon span.activeclr {
  background: green !important;
}
.ribbon span.inactiveclr {
  background: gray !important;
}
.ribbon span.expiryclr {
  background: red !important;
}
.ribbon.activeborder::before,
.ribbon.activeborder::after {
  border: 5px solid green !important;
}
.ribbon.inactiveborder::before,
.ribbon.inactiveborder::after {
  border: 5px solid gray !important;
}
.ribbon.expiryborder::before,
.ribbon.expiryborder::after {
  border: 5px solid red !important;
}

.card_custom_sec {
  border-radius: 24px !important;
  border: 0px !important;
  box-shadow: none !important;
  background-color: var(--blue-grey) !important;
  position: relative;
  z-index: 7;
  overflow: hidden;
}

.purchase-bot .owl-carousel .owl-nav button.owl-prev {
  left: -65px;
  top: 160px;
  position: absolute;
  padding: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../src/Assets/Img/Prev_arrow_group.svg);
  background-size: 100% 100%;
}

.purchase-bot .owl-carousel .owl-nav button.owl-prev:hover {
  transform: scale(1.1);
  transition: 0.5s;
}

.purchase-bot .owl-carousel .owl-nav button.owl-next:hover {
  transform: scale(1.1);
  transition: 0.5s;
}

.purchase-bot .owl-carousel .owl-nav button.owl-prev span {
  display: none;
}

.purchase-bot .owl-carousel .owl-nav button.owl-next {
  right: -65px;
  top: 160px;
  position: absolute;
  padding: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../src/Assets/Img/Next_arrow_group.svg);
  background-size: 100% 100%;
}

.purchase-bot .owl-carousel .owl-nav button.owl-next span {
  display: none;
}

.bot_subscription_txt {
  color: var(--dark-grey);
  font-weight: 500;
  font-size: 15px;
}

.trade-history-table-sec {
  margin-top: 50px;
}

.trade-history-table-sec tbody {
  border-top: none !important;
  border-bottom: none !important;
}

.trade-history-table-sec tbody tr {
  border-bottom: none !important;
  border-top: none !important;
}

.trade-history-table-sec tr th {
  color: var(--dark-grey-1);
  font-weight: 600;
}

.trade-history-table-sec thead {
  background-color: #f6f7f8;
  border-color: transparent;
  /* border: 1px solid var(--lite-grey-1) !important; */
}

.trade-history-table-sec td,
.trade-history-table-sec th {
  border: 0px !important;
}

.trade-history-table-sec td {
  color: var(--dark-grey-1);
  font-weight: normal;
}

.trade-history-table-sec .table_cont {
  /* border: 1px solid var(--lite-grey-1); */
}

.trade-history-table-sec .table-responsive {
  height: 80vh;
  border: 1px solid var(--lite-grey-1);
}

@media screen and (min-width: 1200px) {
  .active_text {
    left: -33px;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .content_section .purchase-bot .trade {
    font-size: 20px;
  }

  .content_section .purchase-bot .amount {
    font-size: 26px;
  }

  .content_section .purchase-bot .activate-bot {
    font-size: 18px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .content_section .purchase-bot .trade {
    font-size: 20px;
  }

  .content_section .purchase-bot .amount {
    font-size: 26px;
  }

  .content_section .purchase-bot .activate-bot {
    font-size: 18px;
  }

  .purchase-bot .owl-carousel .owl-nav button.owl-prev {
    left: -67px;
    width: 40px;
    height: 40px;
  }

  .purchase-bot .owl-carousel .owl-nav button.owl-next {
    right: -66px;
  }
}

@media (max-width: 991px) {
  .content_section .profile-sec {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .dashboard .right_side_sec {
    width: 100%;
  }

  .dashboard .left_side_sec {
    position: fixed;
    z-index: 2;
    /* display: none; */
  }

  .content_section .profile-sec {
    position: relative;
    /* z-index: -1; */
  }

  .meet_bot_bg {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .content_section .purchase-bot .trade {
    font-size: 17px !important;
  }
}

@media (max-width: 576px) {
  .trade-history-table-sec .table-responsive {
    height: 500px;
  }
}

@media (max-width: 380px) {
  .content_section .user_details .user_detail_name {
    font-size: 14px;
  }

  .purchase-bot .owl-carousel .owl-nav button.owl-next {
    width: 30px;
    height: 30px;
    top: 120px;
    right: -45px;
  }

  .purchase-bot .owl-carousel .owl-nav button.owl-prev {
    width: 30px;
    height: 30px;
    top: 120px;
    left: -45px;
  }

  .content_section .user_details .user_detail_op {
    font-size: 14px;
  }

  .content_section .purchase-bot .trade {
    font-size: 15px !important;
  }

  .content_section .purchase-bot .amount {
    font-size: 20px !important;
  }

  .content_section .purchase-bot .activate-bot {
    font-size: 16px;
  }

  .content_section .purchase-bot .activate-bot {
    height: 50px;
  }

  .content_section .purchase-bot .big-bot {
    width: 103px !important;
    height: 103px !important;
  }

  .content_section .purchase-bot .bot-check input {
    width: 23px;
    height: 23px;
  }
}

@media (min-width: 381px) and (max-width: 575px) {
}

.content_section .profile-sec {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

/* Custom Upload btn */
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper .btn {
  border: none;
  outline: none;
  color: var(--green);
  /* background-color: white; */
  background-color: rgb(29, 171, 75, 0.1);
  padding: 6px 14px;
  border-radius: 8px;
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.side_bar_close_btn span {
  background-color: var(--white);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.dashboard_header .hamburger_icon img {
  cursor: pointer;
}

@media (min-width: 768px) {
  .side_bar_close_btn {
    display: none !important;
  }

  .dashboard_header .hamburger_icon img {
    display: none;
  }

  .dashboard_header .hamburger_icon span {
    display: none;
  }
}

@media (max-width: 767px) {
  .content_section .profile {
    text-align: center;
  }
}

@media (max-width: 339px) {
  .noti_cus_drop {
    transform: translate(-111px, 42px) !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .meet_bot_bg {
    left: 31% !important;
    top: -4%;
    width: 350px !important;
    height: 350px !important;
    position: absolute !important;
  }
}

@media (max-width: 480px) {
  .purchase_bot_offcanvas {
    padding: unset !important;
  }
}

.meet_bot_bg {
  left: 38%;
  top: -4%;
  width: 350px !important;
  height: 350px !important;
  position: absolute !important;
}

.page-item:hover {
  background-color: green;
}
.cardheight {
  min-height: 55px;
}
.noti_cus_drop {
  min-width: 15rem;
  height: 400px;
  min-height: 400px;
  max-height: 400px;
  overflow-y: auto;
  padding: 14px 16px !important;
}

.noti_cus_drop .notification_inside_content {
  height: 170px;
  overflow: auto;
}

.noti_cus_drop .notification_inside_content h6 {
  color: var(--dark-grey-1);
  font-weight: 500;
}

.noti_cus_drop .notification_inside_content p {
  color: var(--dark-grey-1);
  font-size: 13px;
}

.noti_cus_drop .notification_inside_content .user_img {
  border-radius: 50%;
  padding: 7px;
  width: 30px;
  height: 30px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: var(--green);
}

.noti_cus_drop .notification_inside_content .user_img svg {
  color: var(--white);
}

.loading_page h2 {
  color: red;
  font-weight: 700;
  font-size: 47px;
}

.loading_page h5 {
  color: #4a7758;
  font-weight: 500;
  font-size: 16px;
}

.loading_page h4 {
  color: #4a7758;
  font-weight: 700;
  font-size: 28px;
  line-height: 1.7;
}

.loading_page h6 {
  color: #4a7758;
  font-weight: 600;
  font-size: 20px;
}

.loading_page img {
  width: 380px;
}

.loading_page .container {
  /* display: flex;
  justify-content: center;
  max-width: 50%;
  align-items: center; */
}

@media (max-width: 575px) {
  .connect_wallet .cmn-btn {
    padding-left: 2px !important;
    padding-right: 2px !important;
    font-size: 12px !important;
  }
  .active_text {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .loading_page h2 {
    font-size: 25px;
  }

  .loading_page h5 {
    font-size: 8px;
  }

  .loading_page h4 {
    font-size: 18px;
  }

  .loading_page h6 {
    font-size: 15px;
  }

  .loading_page img {
    width: 200px !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .loading_page h2 {
    font-size: 28px;
  }

  .loading_page h5 {
    font-size: 10px;
  }

  .loading_page h4 {
    font-size: 18px;
  }

  .loading_page h6 {
    font-size: 16px;
  }

  .loading_page img {
    width: 230px !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .loading_page h2 {
    font-size: 30px;
  }

  .loading_page h5 {
    font-size: 11px;
  }

  .loading_page h4 {
    font-size: 20px;
  }

  .loading_page h6 {
    font-size: 17px;
  }

  .loading_page img {
    width: 280px !important;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .loading_page h2 {
    font-size: 43px;
  }

  .loading_page h5 {
    font-size: 15px;
  }

  .loading_page h4 {
    font-size: 25px;
  }

  .loading_page h6 {
    font-size: 20px;
  }

  .loading_page img {
    width: 370px !important;
  }
}

@media (min-width: 1200px) and (max-height: 640px) {
  .loading_page h2 {
    font-size: 33px !important;
  }

  .loading_page h5 {
    font-size: 12px !important;
  }

  .loading_page h4 {
    font-size: 17px !important;
  }

  .loading_page h6 {
    font-size: 15px !important;
  }

  .loading_page img {
    width: 250px !important;
  }

  #loader-wrapper .content_sec {
    padding-bottom: 0px !important;
  }
}

@media (min-width: 1200px) and (max-height: 730px) {
  .loading_page h2 {
    font-size: 35px;
  }

  .loading_page h5 {
    font-size: 13px;
  }

  .loading_page h4 {
    font-size: 19px;
  }

  .loading_page h6 {
    font-size: 17px;
  }

  .loading_page img {
    width: 280px !important;
  }
}

@media (min-width: 1200px) and (max-height: 680px) {
  #loader {
    height: 100%;
    overflow-y: auto;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .lets_subscribe {
    font-size: 33px !important;
  }
}

@media (max-width: 991px) {
  /* .news_letter_logo{
    width: 300px !important;
    height: 280px !important;
    object-fit: contain;
  } */
}

.news_letter_logo {
  /* width: 400px;
  height: 380px; */
  width: 300px;
  height: 280px;
  object-fit: contain;
}

@media (max-width: 575px) {
  .homepage .our_team .employee:hover {
    transform: scale(1.05) !important;
  }
}

@media (max-width: 1199px) {
  .navbar {
    background-color: var(--green) !important;
  }
}

.custom_hamburger {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-color: var(--white);
  line-height: 1.4em;
  border-radius: 4px;
}

.custom_hamburger svg {
  color: var(--dark-yellow-1);
}

.dash_custom_hamburger {
  cursor: pointer;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dash_custom_hamburger svg {
  color: var(--dark-yellow-1);
}

.custom_header_border {
  border: none !important;
}

@media (max-width: 576px) {
  .custom_logo_nav a {
    padding: 8px 7px !important;
  }

  .custom_logo_nav {
    padding: 8px 7px !important;
  }
}
@media (max-width: 400px) {
  .purchase_bot_custom_btn {
    font-size: 11px;
  }
}

.wallet-content .icon_logo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 5px;
}
.wallet-content p {
  /* margin-bottom: 0px !important; */
}
.wallet-content .card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
}
.wallet-content .row .col {
  /* display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; */
}
.deposit_modal img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}
.deposit_modal ul {
  padding-left: 0px;
}
.deposit_modal ul li {
  list-style-type: none;
  margin-bottom: 5px;
}

.qr_code_modal .modal-header {
  border-bottom: none !important;
}
.withdraw_modal .modal-header {
  border-bottom: none !important;
}
.withdraw_modal .modal-footer {
  border-top: none !important;
}

.withdraw_modal .custom_input {
  border-radius: 3px;
  background-color: var(--lite-grey);
  border: none;
  outline: none;
  box-shadow: 0px;
  height: 40px;
  color: var(--dark-grey) !important;
}

@media (max-width: 575px) {
  .wallet-content .row .col {
    margin-bottom: 10px;
  }
  .withdraw_modal .custom_input {
    width: 200px;
  }
}
/* 
.plan_txt {
  background-color: transparent;
  color: var(--black);
  height: 40px;
  width: 220px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
}
.plan_txt:hover {
  background-color: var(--green);
  color: var(--white);
}
.plan_txt:hover::before {
  position: absolute !important;

  content: "";

  height: 27px !important;

  width: 18px !important;

  background: transparent !important;

  left: 0 !important;

  bottom: 100% !important;

  border-bottom-left-radius: 20px !important;

  box-shadow: 0 17px 0 var(--green) !important;
}
.plan_txt:hover::after {
  position: absolute !important;

  content: "";

  height: 27px !important;

  width: 24px !important;

  background: transparent !important;

  left: -2px !important;

  bottom: -65% !important;

  border-bottom-right-radius: 20px !important;

  box-shadow: 0 17px 0 var(--green) !important;

  transform: rotate(180deg) !important;
}

.plan_txt::before {
  box-shadow: unset !important;
}
.plan_txt::after {
  box-shadow: none !important; 
} */
.botcard .disabled_card {
  opacity: 0.4;
  pointer-events: none;
}
.purchase-bot .disabled_card {
  opacity: 0.4;
  pointer-events: none;
}
.security-settings .nav-link {
  color: var(--black);
  font-weight: 600;
  margin-right: 20px;
  /* border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-bottom: 0px !important; */
  border: none !important;
}
.security-settings .nav-link.active {
  color: var(--green);
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-bottom: 4px solid var(--green) !important;
}
.security-settings .nav-link:hover {
  color: var(--green);
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-bottom: 4px solid var(--green) !important;
}
.two_factor_authentication_content .enabled_status {
  color: var(--black);
  font-weight: 500;
  padding-left: 1rem;
}
.two_factor_authentication_content .enabled {
  color: var(--green);
}
.authentication_factor .qr_img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}
.authentication_factor .disabled_btn {
  height: 45px;
  width: 100%;
}
.authentication_factor .notes,
.authentication_factor .how_to_enabled {
  font-weight: 500;
}
.authentication_factor .form-control {
  border-radius: 3px;
  background-color: var(--lite-grey-2);
  /* border: none; */
  /* border-top: none !important;
  border-bottom: none !important;
  border-right: 1px solid var(--lite-grey) !important;
  border-left: none !important; */
  border: none;
  outline: none;
  box-shadow: none !important;
  height: 55px;
  color: var(--dark-grey) !important;
}
.authentication_factor .input-group-text {
  border-radius: 3px;
  background-color: var(--lite-grey-2);
  border: none;
  /* border-left: 1px solid var(--lite-grey); */
  outline: none;
  box-shadow: none !important;
  height: 55px;
  color: var(--dark-grey) !important;
}
.custom_security {
  border-top: none !important;
  border-bottom: none !important;
  border-right: 1px solid #c0c0c0 !important;
  border-left: none !important;
}
.authentication_factor .copy_icon {
  cursor: pointer;
}

/* .security_txt {
  background-color: transparent;
  color: var(--black);
  height: 40px;
  width: 220px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
} */

/* sidebar bg and color changes */
.dashboard .left_side_sec .sidebar_sec p {
  color: var(--black);
  background-color: transparent;
  height: 40px;
  width: 220px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
}

.dashboard .left_side_sec .sidebar_sec p:hover {
  background-color: var(--green);
  color: var(--white);
}

.dashboard .left_side_sec .sidebar_sec p:hover::after {
  position: absolute !important;
  content: "";
  height: 27px !important;
  width: 24px !important;
  background: transparent !important;
  left: -2px !important;
  bottom: -65% !important;
  border-bottom-right-radius: 20px !important;
  box-shadow: 0 17px 0 var(--green) !important;
  transform: rotate(180deg) !important;
}

.dashboard .left_side_sec .sidebar_sec p:hover::before {
  position: absolute !important;
  content: "";
  height: 27px !important;
  width: 18px !important;
  background: transparent !important;
  left: 0 !important;
  bottom: 100% !important;
  border-bottom-left-radius: 20px !important;
  box-shadow: 0 17px 0 var(--green) !important;
}

/* dashboard active page */
.dash_board .left_side_sec .sidebar_sec .dashboard_text {
  height: 40px;
  color: var(--white);
  background-color: var(--green);
  width: 220px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
}

.dash_board .left_side_sec .sidebar_sec .dashboard_text::after {
  position: absolute !important;
  content: "";
  height: 27px !important;
  width: 24px !important;
  background: transparent !important;
  left: -2px !important;
  bottom: -65% !important;
  border-bottom-right-radius: 20px !important;
  box-shadow: 0 17px 0 var(--green) !important;
  transform: rotate(180deg) !important;
}

.dash_board .left_side_sec .sidebar_sec .dashboard_text::before {
  position: absolute !important;
  content: "";
  height: 27px !important;
  width: 18px !important;
  background: transparent !important;
  left: 0 !important;
  bottom: 100% !important;
  border-bottom-left-radius: 20px;
  box-shadow: 0 17px 0 var(--green) !important;
}

/* wallet active page */
.wallet .left_side_sec .sidebar_sec .wallet_text {
  height: 40px;
  color: var(--white);
  background-color: var(--green);
  width: 220px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
}

.wallet .left_side_sec .sidebar_sec .wallet_text::after {
  position: absolute !important;
  content: "";
  height: 27px !important;
  width: 24px !important;
  background: transparent !important;
  left: -2px !important;
  bottom: -65% !important;
  border-bottom-right-radius: 20px !important;
  box-shadow: 0 17px 0 var(--green) !important;
  transform: rotate(180deg) !important;
}

.wallet .left_side_sec .sidebar_sec .wallet_text::before {
  position: absolute !important;
  content: "";
  height: 27px !important;
  width: 18px !important;
  background: transparent !important;
  left: 0 !important;
  bottom: 100% !important;
  border-bottom-left-radius: 20px;
  box-shadow: 0 17px 0 var(--green) !important;
}

/* purchase history active page */
.trade-history .left_side_sec .sidebar_sec .trade_history_text {
  height: 40px;
  color: var(--white);
  background-color: var(--green);
  width: 220px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
}

.trade-history .left_side_sec .sidebar_sec .trade_history_text::after {
  position: absolute !important;
  content: "";
  height: 27px !important;
  width: 24px !important;
  background: transparent !important;
  left: -2px !important;
  bottom: -65% !important;
  border-bottom-right-radius: 20px !important;
  box-shadow: 0 17px 0 var(--green) !important;
  transform: rotate(180deg) !important;
}

.trade-history .left_side_sec .sidebar_sec .trade_history_text::before {
  position: absolute !important;
  content: "";
  height: 27px !important;
  width: 18px !important;
  background: transparent !important;
  left: 0 !important;
  bottom: 100% !important;
  border-bottom-left-radius: 20px;
  box-shadow: 0 17px 0 var(--green) !important;
}

/* security settings active page */
.security_settings .left_side_sec .sidebar_sec .security_txt {
  height: 40px;
  color: var(--white);
  background-color: var(--green);
  width: 220px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
}

.security_settings .left_side_sec .sidebar_sec .security_txt::after {
  position: absolute !important;
  content: "";
  height: 27px !important;
  width: 24px !important;
  background: transparent !important;
  left: -2px !important;
  bottom: -65% !important;
  border-bottom-right-radius: 20px !important;
  box-shadow: 0 17px 0 var(--green) !important;
  transform: rotate(180deg) !important;
}

.security_settings .left_side_sec .sidebar_sec .security_txt::before {
  position: absolute !important;
  content: "";
  height: 27px !important;
  width: 18px !important;
  background: transparent !important;
  left: 0 !important;
  bottom: 100% !important;
  border-bottom-left-radius: 20px;
  box-shadow: 0 17px 0 var(--green) !important;
}

/* affiliateprogram active page */
.affiliateprogram .left_side_sec .sidebar_sec .affiliateprogram_text {
  height: 40px;
  color: var(--white);
  background-color: var(--green);
  width: 220px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
}

.affiliateprogram .left_side_sec .sidebar_sec .affiliateprogram_text::after {
  position: absolute !important;
  content: "";
  height: 27px !important;
  width: 24px !important;
  background: transparent !important;
  left: -2px !important;
  bottom: -65% !important;
  border-bottom-right-radius: 20px !important;
  box-shadow: 0 17px 0 var(--green) !important;
  transform: rotate(180deg) !important;
}

.affiliateprogram .left_side_sec .sidebar_sec .affiliateprogram_text::before {
  position: absolute !important;
  content: "";
  height: 27px !important;
  width: 18px !important;
  background: transparent !important;
  left: 0 !important;
  bottom: 100% !important;
  border-bottom-left-radius: 20px;
  box-shadow: 0 17px 0 var(--green) !important;
}

/* start of hariharan css 15-11-2023  */

.card_min_max_ht {
  /* min-height: 458px;
  max-height: 458px; */
}
.card_img_wrapper {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  overflow: hidden;
}
.card_img_wrapper img {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
  margin-top: 0px !important;
}
.card_txt_clr {
  color: white !important;
}
.card_txt_clr:hover {
  color: black !important;
}
.wallet_card_wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  padding: 1rem 1rem;
}
.wallet_card_wrapper tr th {
  font-weight: 600 !important;
  height: 70px !important;
  vertical-align: middle;
}
.wallet_card_wrapper tr td {
  height: 90px !important;
  vertical-align: middle;
  min-width: 90px;
}
.wallet_card_wrapper table tbody {
  border-top: 1px solid grey !important;
}
.modal_container .modal-body input {
  min-height: 50px !important;
}
.copy_input {
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  /* transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
}
.copy_content {
  min-height: 50px;
  padding: 0.375rem 0.75rem;
  color: grey;
}
.wallet-adrs {
  word-break: break-all !important;
}
.qr_bar_code {
  height: 130px !important;
  width: 130px !important;
}
.wallet_bal {
  background-color: #e8e8e8;
  min-height: 50px;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}
.wallet_bal p {
  color: #61646a;
  word-break: break-all;
}
.wallet_bal p span {
  color: black;
  font-weight: 500;
}
.done_btn button {
  min-height: 50px !important;
}
.copy_btn {
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
  color: grey !important;
  cursor: pointer;
}
.copy_btn:hover {
  transform: scale(98%);
}

.price-dtls .card-body {
  background-color: #eef0f6;
}
.price-dtls P {
  /* word-break: break-all; */
  font-weight: 700;
}

.price-dtls p s {
  color: black !important;
}
.price-dtls p span {
  color: red;
  font-weight: 500;
}
.amt_prc {
  margin-top: 0px !important;
  font-size: 11px !important;
  text-align: start !important;
}
.prc_name {
  font-size: 11px !important;
}
.price-dtls p span {
  font-size: 9px !important;
}
.canvas_tle {
  color: var(--dark-grey);
  font-weight: 700;
  font-size: 17px;
}
.fnt_itl {
  font-style: italic;
  min-height: 49px !important;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lite-grey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--green);
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */
.table_new th {
  height: 50px;
  vertical-align: middle;
}
.table_new td {
  height: 70px;
  vertical-align: middle;
  font-size: 14px;
}
.btn_fnt-sz {
  font-size: 14px;
}

.table_new th {
  min-width: 120px;
}
.input_with_txt input {
  width: 100% !important;
  border: none !important;
  outline: none !important;
  color: grey;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.input_txt_bg {
  background-color: #e8e8e8;
  font-style: italic;
  font-weight: 500;
}
.deposit_btn {
  background: var(--yellow) !important;
  color: black !important;
}
.deposit_btn:hover {
  background: var(--green) !important;
  color: white !important;
}
.edit_icn_btn {
  cursor: pointer;
  color: #403c3e;
}
.current_email {
  min-height: 38px;
  background: #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  display: flex;
  align-items: center;
}
.current_email p {
  word-break: break-all !important;
}

/* media  */

@media (min-width: 576px) {
  .prc_name {
    font-size: 13px !important;
  }
  .amt_prc {
    font-size: 13px !important;
  }
  .price-dtls p span {
    font-size: 11px !important;
  }
  .canvas_tle {
    font-size: 19px;
  }
}
@media (min-width: 768px) {
  .prc_name {
    font-size: 16px !important;
  }
  .amt_prc {
    font-size: 16px !important;
  }
  .price-dtls p span {
    font-size: 14px !important;
  }
  .canvas_tle {
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .prc_name {
    font-size: 14px !important;
  }
  .amt_prc {
    font-size: 14px !important;
  }
  .price-dtls p span {
    font-size: 13px !important;
  }
  .canvas_tle {
    font-size: 22px;
  }
}
@media (min-width: 1441px) {
  .prc_name {
    font-size: 15px !important;
  }
  .amt_prc {
    font-size: 15px !important;
  }
  .price-dtls p span {
    font-size: 13px !important;
  }
  .canvas_tle {
    font-size: 26px;
  }
}

@media (min-width: 1920px) {
  .prc_name {
    font-size: 20px !important;
  }
  .amt_prc {
    font-size: 20px !important;
  }
  .price-dtls p span {
    font-size: 19px !important;
  }
  .canvas_tle {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .modal_body_ht {
    max-height: 400px !important;
    overflow-y: auto !important;
  }
}

/* end of hariharan css 15-11-2023  */

.input_with_eye_pwd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input_with_eye_pwd input {
  border: none;
  border-radius: 0px;
  background-color: transparent;
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0.25) !important;
}
.input_with_eye_pwd .fa {
  cursor: pointer;
  padding-right: 10px;
}
.input_with_eye_pwd .fa:hover {
  color: var(--green);
}
.btn_style_ena {
  max-width: 150px;
  margin-left: auto;
}

@media only screen and (max-width: 432px) {
  .security-settings .nav-link {
    font-size: 10px;
    margin-right: 5px;
  }
}

/* start of hariharan css 12-8-23 */

.not_rd_btn {
  border: none !important;
  outline: none !important;
  background: none !important;
  color: var(--green) !important;
  font-style: italic !important;
  font-size: 14px !important;
  text-decoration: underline !important;
}
.not_rd_btn:hover {
  color: var(--red) !important;
}
.not_time_txt {
  font-size: 13px;
  color: var(--text-gray);
}
.not_msg_txt {
  font-size: 14px;
}
.new_not_value {
  border-bottom: 1px solid #e8e8e8;
}
.not_msg_wrap {
  max-height: 180px;
  overflow-y: auto;
}
.not_shwall_btn {
  border: none !important;
  outline: none !important;
  background: none !important;
  color: var(--green) !important;
  font-size: 14px !important;
}
.not_shwall_btn:hover {
  color: var(--red) !important;
}
.cpy_icn_clk {
  cursor: pointer;
}
.not_count_wrap {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--yellow);
  display: flex;
  align-items: center;
  justify-content: center;
  top: -9px;
  right: -4px;
  font-size: 8px;
  color: var(--green);
}

.notification .left_side_sec .sidebar_sec .notification_text {
  height: 40px;
  color: var(--white);
  background-color: var(--green);
  width: 220px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
}
.notification .left_side_sec .sidebar_sec .notification_text::before {
  position: absolute !important;
  content: "";
  height: 27px !important;
  width: 18px !important;
  background: transparent !important;
  left: 0 !important;
  bottom: 100% !important;
  border-bottom-left-radius: 20px;
  box-shadow: 0 17px 0 var(--green) !important;
}
.notification .left_side_sec .sidebar_sec .notification_text::after {
  position: absolute !important;
  content: "";
  height: 27px !important;
  width: 24px !important;
  background: transparent !important;
  left: -2px !important;
  bottom: -65% !important;
  border-bottom-right-radius: 20px !important;
  box-shadow: 0 17px 0 var(--green) !important;
  transform: rotate(180deg) !important;
}
.cus_inpt_scl_rem input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.wth_drw_inpt {
  padding: 0.375rem 0.75rem !important;
  color: gray !important;
}

.wth_drw_inpt:focus-visible {
  outline: none !important;
}
@media (max-width: 1199px) {
  .heads_rgt_side {
    max-width: 230px;
    min-height: calc(100vh - 58px);
    right: 0;
    left: unset;
    max-height: calc(100vh - 58px);
    overflow-y: auto;
  }
  .heads_icn_change .head_hambgr_menu {
    display: none;
  }
  .head_cls_menu {
    font-size: 25px;
  }
  .heads_icn_change.collapsed .head_cls_menu {
    display: none;
  }
  .heads_icn_change.collapsed .head_hambgr_menu {
    display: block;
  }
  .nav_align_lft a {
    padding-left: 0px;
  }
  .just_cont_end_nav {
    justify-content: start;
  }
  .heads_btn_alg-strt {
    text-align: start;
  }
  .heads_nvlnk_pads {
    padding-left: 20px;
    padding-right: 20px;
  }
  .btn_bg-rmv {
    background-color: transparent !important;
    color: black !important;
    border: none !important;
    outline: none !important;
    padding: 0px !important;
    transition: 0.3s;
    cursor: pointer;
  }
  .btn_bg-rmv:hover {
    color: var(--yellow) !important;
    background-color: transparent !important;
    padding: 0px !important;
    outline: none !important;
    border: none !important;
  }
  .heads_drp_dwn_men {
    width: fit-content !important;
    min-width: fit-content !important;
    max-width: fit-content !important;
  }
}
/* end of hariharan css 12-8-23 */

/* sakthi 29-01-24 */
.video_sec {
  padding-top: 84px !important;
}
.video_sec h2 {
  color: var(--green);
  font-weight: 600;
}
.video_sec h4 {
  color: var(--green);
  font-weight: 600;
}
.video_sec .download_btn {
  padding: 6px 35px;
}
.video_sec .video_display {
  /* margin: 40px 0px; */
}
.video_sec .video_display video {
  width: 100%;
  height: 500px;
  border-radius: 10px;
  object-fit: cover;
}

.custom_countdown_timer {
  position: relative;
}

.custom_countdown_timer .count_down {
  position: absolute;
  top: 100px;
  right: 5%;
}
.custom_countdown_timer .count_down span {
  /* color: var(--green);
  font-size: 30px;
  font-weight: 600; */
}
.count_down .custom_exp {
  display: flex;
  gap: 15px;
}
.count_down .custom_exp div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.count_down .custom_exp .header {
  font-weight: 500;
  font-size: 10px;
  margin-top: 1px;
}
.count_down .custom_exp .val {
  background: var(--lite-grey);
  font-size: 15px;
  padding: 5px 5px;
  border-radius: 4px;
  font-weight: 500;
  color: var(--green);
  width: 50px;
  text-align: center;
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .custom_countdown_timer .count_down {
    top: 100px !important;
    right: 2% !important;
  }
  .count_down_heading_title {
    font-size: 15px !important;
    margin-left: 18px !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .custom_countdown_timer .count_down {
    top: 87px !important;
    left: 2% !important;
  }
  .count_down .custom_exp .val {
    font-size: 12px !important;
  }
  .count_down .custom_exp .header {
    font-size: 8px !important;
  }
  .count_down_heading_title {
    font-size: 15px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* .custom_countdown_timer .count_down {
    top: 72px !important;
    right: 2% !important;
  } */
  .custom_countdown_timer .count_down {
    top: 87px !important;
    left: 2% !important;
  }
  .count_down .custom_exp .val {
    font-size: 12px !important;
  }
  .count_down .custom_exp .header {
    font-size: 8px !important;
  }
  .count_down .custom_exp .val {
    width: 43px !important;
  }
  .count_down_heading_title {
    font-size: 14px !important;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .custom_countdown_timer .count_down {
    top: 77px !important;
    left: 2% !important;
  }
  .count_down .custom_exp .val {
    font-size: 12px !important;
  }
  .count_down .custom_exp .header {
    font-size: 9px !important;
  }
  .count_down .custom_exp .val {
    width: 40px !important;
  }
  .count_down_heading_title {
    font-size: 13px !important;
  }
}
@media (max-width: 480px) {
  .custom_countdown_timer .count_down {
    top: 77px !important;
    left: 2% !important;
  }
  .count_down .custom_exp .val {
    font-size: 10px !important;
  }
  .count_down .custom_exp .header {
    font-size: 7px !important;
  }
  .count_down .custom_exp {
    display: flex;
    gap: 10px;
  }
  .count_down .custom_exp .val {
    width: 40px !important;
  }
  .count_down_heading_title {
    font-size: 12px !important;
  }
}

.count_down_expired {
  color: var(--green);
}
.count_down_heading_title {
  text-align: left;
  margin-bottom: 10px;
  margin-left: 12px;
  color: var(--green);
  font-weight: 600;
}
